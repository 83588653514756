<script>
import { mapState } from "vuex";
import Layout from "@/router/layouts/main";
import moment from "moment";
import { api } from "@/state/services";
import Binary from "@/components/widgets/binary-user";
import { required } from "vuelidate/lib/validators";

export default {
  locales: {
    pt: {
      'Left Team': 'Equipe Esquerda',
      'Right Team': 'Equipe Direita',
      'CURRENT': 'ATUAL',
      'TOTAL': 'TOTAL',
      'TEAM': 'EQUIPE',
      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.',

      'Username': 'Usuário',
      'Username is required.': 'O usuário é obrigatório.',
      'Search': 'Buscar',
      'The username was not found.': 'O usuário não foi encontrado.',
    },
    es: {
      'Left Team': 'Equipe Izquierda',
      'Right Team': 'Equipe Derecha',
      'CURRENT': 'ACTUAL',
      'TOTAL': 'TOTAL',
      'TEAM': 'EQUIPO',
      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.',

      'Username': 'Usuário',
      'Username is required.': 'Nombre de usuario es requerido.',
      'Search': 'Buscar',
      'The username was not found.': 'No se encontró el nombre de usuario.',
    }
  },
  components: {
    Layout,
    Binary,
  },
  computed: {
    ...mapState({
      account: (state) => state.account,
    }),
  },
  data() {
    return {
      month_year: moment().format("MM/YY"),

      loading: true,
      errored: false,

      id: this.$route.params.id,
      username: null,
      name: null,
      image: null,

      resume: {
        points: {
          left: 0,
          right: 0,
        },
        pairs: 0,
        bonus: "R$ -,--",
      },

      left: {
        current: '0',
        total: '0',
        users: '0',
      },
      right: {
        current: '0',
        total: '0',
        users: '0',
      },

      tree: {
        left: {
          c1: { l1: false },
          c2: { l1: false, l2: false },
          c3: { l1: false, l2: false, l3: false, l4: false },
          c4: { l1: false, l2: false, l3: false, l4: false, l5: false, l6: false, l7: false, l8: false },
        },
        right: {
          c1: { l1: false },
          c2: { l1: false, l2: false },
          c3: { l1: false, l2: false, l3: false, l4: false },
          c4: { l1: false, l2: false, l3: false, l4: false, l5: false, l6: false, l7: false, l8: false },
        }
      },

      upline: {
        left: 0,
        right: 0,
      },

      final: {
        left: 0,
        right: 0,
      },

      alert: { type: '', message: '' },
      search: {
        loading: false,
        username: '',
      },
    }
  },
  validations: {
    search: {
      username: { required }
    },
  },
  methods: {
    getBinary() {
      if (this.id) {
        api
          .get('network/binary/' + this.id)
          .then(response => {
            if (response.data.status == 'success') {
              this.resume.points.left = this.$options.filters.points(response.data.resume.points.left);
              this.resume.points.right = this.$options.filters.points(response.data.resume.points.right);
              this.resume.pairs = this.$options.filters.points(response.data.resume.pairs);
              this.resume.bonus = this.$options.filters.currency(response.data.resume.bonus);

              this.left.current = response.data.points.current.left
              this.left.total = response.data.points.total.left
              this.left.users = response.data.users.left

              this.right.current = response.data.points.current.right
              this.right.total = response.data.points.total.right
              this.right.users = response.data.users.right

              this.username = response.data.user.username
              this.name = response.data.user.name
              this.image = response.data.user.image

              this.tree = response.data.tree
              this.upline = response.data.upline
              this.final = response.data.final
            }
          })
          .catch(error => {
            this.errored = error
            this.loading = false
          })
          .finally(() => {
            this.errored = false
            this.loading = false
          })
      }
    },
    setBinary(id) {
      this.id = id
      this.loading = true
      this.getBinary()
    },
    searchUser() {
      this.$v.$touch();

      if (this.search.username) {
        this.search.loading = true

        api
          .post('network/binary/search', {
            username: this.search.username,
          })
          .then(response => {
            if (response.data.status == 'success') {
              this.search.username = ''
              this.$v.$reset()

              this.$router.push('/network/binary/' + response.data.user.id);
            } else {
              this.$toast.error(response.data.message);
            }

            this.search.loading = false
          })
      }
    }
  },
  mounted() {
    if (this.$route.params.id) {
      this.id = this.$route.params.id
    } else {
      this.id = this.account.user.id
    }

    this.getBinary();
  },
  watch: {
    '$route.params.id': function (id) {
      if (id) {
        this.loading = true
        this.id = id
      } else {
        this.id = this.account.user.id
      }

      this.getBinary()
    }
  }
};
</script>

<template>
  <Layout>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <router-link tag="a" to="/">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
            <path d="M5 22h14a2 2 0 0 0 2-2v-9a1 1 0 0 0-.29-.71l-8-8a1 1 0 0 0-1.41 0l-8 8A1 1 0 0 0 3 11v9a2 2 0 0 0 2 2zm5-2v-5h4v5zm-5-8.59 7-7 7 7V20h-3v-5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v5H5z">
            </path>
          </svg>
        </router-link>
      </li>
      <li class="breadcrumb-item">Rede</li>
    </ol>

    <div class="page-title-box d-md-flex justify-content-between">
      <div>
        <h3>Binário</h3>
        <p></p>
      </div>
    </div>

    <b-form @submit.prevent="searchUser" class="mb-3">
      <b-input-group>
        <b-form-input id="search" v-model="search.username" type="text" inputmode="decimal" placeholder="nome de usuário" autocomplete="off"></b-form-input>
        <b-input-group-append>
          <b-button :disabled="search.loading == true || search.username === ''" type="submit" variant="default">
            {{ t('Search') }}
            <b-spinner v-if="search.loading" small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
          </b-button>
        </b-input-group-append>
      </b-input-group>
    </b-form>



    <div class="row">
      <div class="col-md-6">
        <fieldset class="pb-0">
          <legend>
            Pontos
          </legend>
          <div class="row">
            <div class="col-6 mb-3">
              <div class="d-flex align-items-center">
                <div>
                  <div class="bg-light rounded py-1 px-2">
                    <i class="bx bx-subdirectory-left font-size-24 my-1 align-middle"></i>
                  </div>
                </div>
                <div class="flex-fill ml-2">
                  <h5 class="mb-0 notranslate font-weight-bold">{{ resume.points.left }}</h5>
                  <p class="font-weight-medium mb-0 text-uppercase font-weight-light font-size-12">Lado Esquerdo</p>
                </div>
              </div>
            </div>
            <div class="col-6 mb-3">
              <div class="d-flex align-items-center">
                <div>
                  <div class="bg-light rounded py-1 px-2">
                    <i class="bx bx-subdirectory-right font-size-24 my-1 align-middle"></i>
                  </div>
                </div>
                <div class="flex-fill ml-2">
                  <h5 class="mb-0 notranslate font-weight-bold">{{ resume.points.right }}</h5>
                  <p class="font-weight-medium mb-0 text-uppercase font-weight-light font-size-12">Lado Direito</p>
                </div>
              </div>
            </div>
          </div>
        </fieldset>
      </div>
      <div class="col-md-6">
        <fieldset class="pb-0">
          <legend>
            Previsão de Bônus <sup class="font-weight-light">{{ month_year }}</sup>
          </legend>
          <div class="row">
            <div class="col-6 mb-3">
              <div class="d-flex align-items-center">
                <div>
                  <div class="bg-light rounded py-1 px-2">
                    <i class="bx bx-check-double font-size-24 my-1 align-middle"></i>
                  </div>
                </div>
                <div class="flex-fill ml-2">
                  <h5 class="mb-0 notranslate font-weight-bold">{{ resume.pairs }}</h5>
                  <p class="font-weight-medium mb-0 text-uppercase font-weight-light font-size-12">Pares</p>
                </div>
              </div>
            </div>
            <div class="col-6 mb-3">
              <div class="d-flex align-items-center">
                <div>
                  <div class="bg-light rounded py-1 px-2">
                    <i class="bx bx-dollar font-size-24 my-1 align-middle"></i>
                  </div>
                </div>
                <div class="flex-fill ml-2">
                  <h5 class="mb-0 notranslate font-weight-bold">{{ resume.bonus }}</h5>
                  <p class="font-weight-medium mb-0 text-uppercase font-weight-light font-size-12">Bônus</p>
                </div>
              </div>
            </div>
          </div>
        </fieldset>
      </div>
    </div>

    <div class="card">
      <div class="card-body table-responsive">
        <div v-if="errored">{{ t('We apologize, we are unable to retrieve information at this time. Please try again later.') }}</div>
        <div v-else id="binary" class="table-responsive">
          <table id="tree" class="table" style="margin:0;">
            <tr class="a1">
              <td colspan="11">
                <router-link :to="'/network/binary/' + final.left" class="btn btn-default mr-2">FINAL</router-link>
                <router-link :to="'/network/binary/' + upline.left" class="btn btn-default">UP</router-link>
              </td>
              <td colspan="10" class="team-item">
                <div v-if="loading"><template><vue-content-loading :width="20" :height="20">
                      <circle cx="10" cy="10" r="10" />
                    </vue-content-loading></template></div>
                <div v-else-if="image"><img :src="require('@/assets/images/plans/' + image)"><span>{{ username }}</span></div>
                <div v-else><img src="@/assets/images/logo-icon.png"><span>{{ username }}</span></div>
              </td>
              <td colspan="11">
                <router-link :to="'/network/binary/' + upline.right" class="btn btn-default mr-2">UP</router-link>
                <router-link :to="'/network/binary/' + final.right" class="btn btn-default">FINAL</router-link>
              </td>
            </tr>
            <tr class="a2">
              <td colspan="8"></td>
              <td colspan="8" class="aleft"></td>
              <td colspan="8" class="aright"></td>
              <td colspan="8"></td>
            </tr>
            <tr class="a2">
              <td colspan="8" class="bleft"></td>
              <td colspan="8"></td>
              <td colspan="8" class="bleft"></td>
              <td colspan="8"></td>
            </tr>
            <tr>
              <td colspan="8" class="bleft"></td>
              <td colspan="8"></td>
              <td colspan="8" class="bleft"></td>
              <td colspan="8"></td>
            </tr>
            <tr class="a1">
              <td colspan="16" class="team-item">
                <Binary :loading="loading" :tree="tree.left.c1.l1" />
              </td>
              <td colspan="16" class="team-item">
                <Binary :loading="loading" :tree="tree.right.c1.l1" />
              </td>
            </tr>
            <tr class="a3">
              <td colspan="4"></td>
              <td colspan="4" class="aleft"></td>
              <td colspan="4" class="aright"></td>
              <td colspan="4"></td>
              <td colspan="4"></td>
              <td colspan="4" class="aleft"></td>
              <td colspan="4" class="aright"></td>
              <td colspan="4"></td>
            </tr>
            <tr class="a3">
              <td colspan="4" class="bleft"></td>
              <td colspan="4"></td>
              <td colspan="4" class="bleft"></td>
              <td colspan="4"></td>
              <td colspan="4" class="bleft"></td>
              <td colspan="4"></td>
              <td colspan="4" class="bleft"></td>
              <td colspan="4"></td>
            </tr>
            <tr class="a2">
              <td colspan="8" class="team-item">
                <Binary :loading="loading" :tree="tree.left.c2.l1" />
              </td>
              <td colspan="8" class="team-item">
                <Binary :loading="loading" :tree="tree.left.c2.l2" />
              </td>
              <td colspan="8" class="team-item">
                <Binary :loading="loading" :tree="tree.right.c2.l1" />
              </td>
              <td colspan="8" class="team-item">
                <Binary :loading="loading" :tree="tree.right.c2.l2" />
              </td>
            </tr>
            <tr class="a4">
              <td colspan="2"></td>
              <td colspan="2" class="aleft"></td>
              <td colspan="2" class="aright"></td>
              <td colspan="2"></td>
              <td colspan="2"></td>
              <td colspan="2" class="aleft"></td>
              <td colspan="2" class="aright"></td>
              <td colspan="2"></td>
              <td colspan="2"></td>
              <td colspan="2" class="aleft"></td>
              <td colspan="2" class="aright"></td>
              <td colspan="2"></td>
              <td colspan="2"></td>
              <td colspan="2" class="aleft"></td>
              <td colspan="2" class="aright"></td>
              <td colspan="2"></td>
            </tr>
            <tr class="a4">
              <td colspan="2" class="bleft"></td>
              <td colspan="2"></td>
              <td colspan="2" class="bleft"></td>
              <td colspan="2"></td>
              <td colspan="2" class="bleft"></td>
              <td colspan="2"></td>
              <td colspan="2" class="bleft"></td>
              <td colspan="2"></td>
              <td colspan="2" class="bleft"></td>
              <td colspan="2"></td>
              <td colspan="2" class="bleft"></td>
              <td colspan="2"></td>
              <td colspan="2" class="bleft"></td>
              <td colspan="2"></td>
              <td colspan="2" class="bleft"></td>
              <td colspan="2"></td>
            </tr>
            <tr class="a3">
              <td colspan="4" class="team-item">
                <Binary :loading="loading" :tree="tree.left.c3.l1" />
              </td>
              <td colspan="4" class="team-item">
                <Binary :loading="loading" :tree="tree.left.c3.l2" />
              </td>
              <td colspan="4" class="team-item">
                <Binary :loading="loading" :tree="tree.left.c3.l3" />
              </td>
              <td colspan="4" class="team-item">
                <Binary :loading="loading" :tree="tree.left.c3.l4" />
              </td>
              <td colspan="4" class="team-item">
                <Binary :loading="loading" :tree="tree.right.c3.l1" />
              </td>
              <td colspan="4" class="team-item">
                <Binary :loading="loading" :tree="tree.right.c3.l2" />
              </td>
              <td colspan="4" class="team-item">
                <Binary :loading="loading" :tree="tree.right.c3.l3" />
              </td>
              <td colspan="4" class="team-item">
                <Binary :loading="loading" :tree="tree.right.c3.l4" />
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </Layout>
</template>
