<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";
import Stat from "@/components/widgets/stat";

export default {
  locales: {
    pt: {
      Team: "Equipe",
      Username: "Usuário",
      Name: "Nome",
      Date: "Data",
      Level: "Nível",
      Plano: "Plano",
      Status: "Status",
      Qualified: "Qualificado",
      Unqualified: "Não Qualificado",
      Left: "Esquerdo",
      Right: "Direito",
      Pending: "Pendente",
      Approved: "Aprovado",
      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.",
      "No records found.": "Nenhum registro encontrado.",
      Graduation: "Graduação",
    },
    es: {
      Team: "Equipe",
      Username: "Nombre de Usuario",
      Name: "Nombre",
      Date: "Data",
      Level: "Nivel",
      Plano: "Plano",
      Status: "Status",
      Qualified: "Calificado",
      Unqualified: "No Cualificado",
      Left: "Izquierdo",
      Right: "Derecho",
      Pending: "Pendiente",
      Approved: "Aprobado",
      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.",
      "No records found.": "No se encontraron registros.",
      Graduation: "Graduación",
    },
  },
  components: {
    Layout,
    Stat,
  },
  data() {
    return {
      page: 1,
      pages: 0,
      table: {
        heade: [
          "Username",
          "Name",
          "Date",
          "Level",
          "Plano",
          "Binário",
        ],
        body: null,
      },

      statData: null,
    };
  },
  methods: {
    getTeam() {
      this.table.body = null;

      api
        .get("network/team?page=" + this.page)
        .then((response) => {
          if (response.data.status == "success") {
            this.table.body = response.data.list;
            this.statData = [
              {
                type: "line",
                icon: "bx bx-user-plus",
                title: "Total da Equipe",
                value: response.data.total,
              },
            ];
            this.pages = response.data.pages;
          }
        })
        .catch(() => {
          this.table.body = [];
        })
    },
  },
  mounted() {
    this.getTeam();
  },
  watch: {
    page: function () {
      this.getTeam();
    },
  },
};
</script>

<template>
  <Layout>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <router-link tag="a" to="/">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
            <path d="M5 22h14a2 2 0 0 0 2-2v-9a1 1 0 0 0-.29-.71l-8-8a1 1 0 0 0-1.41 0l-8 8A1 1 0 0 0 3 11v9a2 2 0 0 0 2 2zm5-2v-5h4v5zm-5-8.59 7-7 7 7V20h-3v-5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v5H5z">
            </path>
          </svg>
        </router-link>
      </li>
      <li class="breadcrumb-item">Rede</li>
    </ol>

    <div class="page-title-box d-md-flex justify-content-between">
      <div>
        <h3>{{ t('Team') }}</h3>
        <p></p>
      </div>
    </div>

    <div class="row">
      <div v-for="stat of statData" :key="stat.icon" class="col-md-4">
        <Stat :type="stat.type" :icon="stat.icon" :title="stat.title" :value="stat.value" />
      </div>
    </div>

    <div v-if="!table.body" class="card">
      <div class="card-body min-vh-25 d-flex justify-content-center align-items-center">
        <b-spinner variant="default" role="status"></b-spinner>
      </div>
    </div>

    <div v-else-if="table.body && table.body.length == 0" class="card">
      <div class="card-body min-vh-25 d-flex justify-content-center align-items-center">
        <div class="text-center">
          <i class="far fa-sad-cry font-size-24"></i><br>
          nenhum registro encontrado
        </div>
      </div>
    </div>

    <div v-else class="card">
      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-centered table-nowrap">
            <thead class="thead-light">
              <tr>
                <th v-for="th in table.heade" :key="th">{{ t(th) }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(td, index) in table.body" :key="index">
                <td v-for="(data, row) in td" :key="row">
                  <div v-if="row == 'package'">
                    <span class="badge badge-soft-dark font-size-12">{{
                      data
                      }}</span>
                  </div>
                  <div v-else-if="row === 'monthly'">
                    <span v-if="data === 'inactive'" class="badge badge-soft-danger font-size-12">INATIVO</span>
                    <span v-else-if="data === 'active'" class="badge badge-soft-success font-size-12">ATIVO</span>
                  </div>
                  <div v-else-if="row == 'graduation'">
                    <span class="badge font-size-12">{{ data.name }}</span>
                  </div>
                  <div v-else-if="row === 'status'">
                    <span v-if="data === 'Pending'" class="badge badge-soft-warning font-size-12">{{ t(data) }}</span>
                    <span v-else-if="data === 'Approved'" class="badge badge-soft-success font-size-12">{{ t(data) }}</span>
                  </div>
                  <div v-else-if="row === 'binary'">
                    <span v-if="data.side === 'l'" class="font-size-12">ESQUERDO</span>
                    <span v-else-if="data.side === 'r'" class="font-size-12">DIREITO</span>
                    –
                    <span v-if="data.status === 'inactive'" class="badge badge-soft-warning font-size-12">INATIVO</span>
                    <span v-else-if="data.status === 'active'" class="badge badge-soft-success font-size-12">ATIVO</span>
                  </div>
                  <div v-else class="notranslate">
                    {{ data }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <paginate v-if="table.body && table.body.length > 0" v-model="page" :page-count="pages" :page-range="3" :margin-pages="2" prev-text="<i class='mdi mdi-chevron-left'></i>"
          next-text="<i class='mdi mdi-chevron-right'></i>" :container-class="'pagination pagination-rounded justify-content-center mt-4'" :page-class="'page-item'" :page-link-class="'page-link'"
          :prev-link-class="'page-link'" :next-link-class="'page-link'" :break-view-link-class="'page-link'">
        </paginate>
      </div>
    </div>
  </Layout>
</template>
