<script>
import Layout from "@/router/layouts/franchise";
import { api } from "@/state/services";

export default {
  locales: {
    pt: {
      Inventory: "Estoque",
      Orders: "Pedidos",
    },
    es: {
      Inventory: "Estoque",
      Orders: "Pedidos",
    },
  },
  components: {
    Layout,
  },
  data() {
    return {
      orders: [],
    };
  },
  methods: {
    getOrders() {
      api
        .get("franchise/points/orders")
        .then((response) => {
          if (response.data.status == "success") {
            this.orders = response.data.list;
          }
        })
        .catch((error) => {
          if (error) {
            this.orders = [];
          }
        });
    },
  },
  mounted() {
    this.getOrders();
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">Pontuações › {{ t("Orders") }}</h4>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <div class="table-responsive">
          <vcl-list v-if="!orders" class="col-lg-6"></vcl-list>
          <template v-else-if="orders.length == 0">
            Nenhum registro encontrado.
          </template>
          <table v-else class="table table-nowrap">
            <thead class="thead-light">
              <tr>
                <th>Pedido</th>
                <th>Data</th>
                <th>Usuário</th>
                <th>Nome</th>
                <th>Total</th>
                <th>Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(order, index) in orders" :key="index">
                <td class="align-middle">{{ order.id }}</td>
                <td class="align-middle">{{ order.date }}</td>
                <td class="align-middle">{{ order.username }}</td>
                <td class="align-middle">{{ order.name }}</td>
                <td class="align-middle">{{ order.total | currency }}</td>
                <td class="align-middle">{{ order.status }}</td>
                <td class="text-right">
                  <router-link :to="'/franchise/points/orders/' + order.id" class="btn btn-default btn-sm btn-rounded">
                    {{ t("Ver") }}
                    <i class="mdi mdi-arrow-right ml-1"></i>
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </Layout>
</template>
