<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";
import { mapState } from "vuex";
import moment from "moment";
import Banners from "@/components/widgets/banners";
import Stat from "@/components/widgets/stat";
import ChartGraduation from "@/components/widgets/chart-graduation.vue";
import ChartBonus from "@/components/widgets/chart-bonus";
import ChartPoints from "@/components/widgets/chart-points";
import ChartOrders from "@/components/widgets/chart-orders";

export default {
  locales: {
    pt: {
    },
    es: {
    }
  },
  components: {
    Layout,
    Banners,
    Stat,
    ChartGraduation,
    ChartBonus,
    ChartPoints,
    ChartOrders
  },
  computed: {
    ...mapState({
      account: state => state.account
    }),
  },
  data() {
    return {
      month_year: moment().format("MM/YY"),

      monthly: "inactive",

      total: {
        prediction: "R$ -,--",
        bonus: "R$ -,--",
        balance: "R$ -,--",
      },

      graduation: {
        id: null,
        name: '-',
        image: null
      },

      statData: null,
      wallets: null,

      cart: {
        items: {
          loading: true,
          total: 0,
          list: null
        },
        points: {
          unilevel: 0,
          graduation: 0,
        },
        subtotal: '0.00',
        sale: '0.00',
        shipping: {
          method: '-',
          value: '-',
        },
        total: '0.00'
      },

      binary: {
        modal: false,
        loading: false,
        side: "",

        points: {
          left: 0,
          right: 0,
        },
        pairs: 0,
        bonus: "R$ -,--",
      },

      referral: {
        sponsored: process.env.VUE_APP_URL_PAINEL + this.$store.state.account.user.username,
        ecommerce: 'https://' + this.$store.state.account.user.username + '.' + process.env.VUE_APP_URL_ECOMMERCE
      }
    };
  },
  methods: {
    getMonthly() {
      api.get("reports/monthly").then((response) => {
        this.monthly = response.data.status;
        this.getStatData();
      });
    },
    getGraduation() {
      api
        .get('reports/graduation')
        .then(response => {
          if (response.data.status == 'success') {
            this.graduation.id = response.data.current.id
            this.graduation.name = response.data.current.name
            this.graduation.image = response.data.current.image
          }
        })
    },
    getTotalPrediction() {
      api.get("reports/bonus/prediction").then((response) => {
        if (response.data.status == "success") {
          this.total.prediction = this.$options.filters.currency(
            response.data.total
          );
          this.getStatData();
        }
      });
    },
    getTotalBonus() {
      api
        .get('reports/bonus/total')
        .then(response => {
          if (response.data.status == 'success') {
            this.total.bonus = this.$options.filters.currency(response.data.total)
            this.getStatData();
          }
        })
    },
    getTotalBalance() {
      api
        .get('wallet/balance/network')
        .then(response => {
          if (response.data.status == 'success') {
            this.total.balance = this.$options.filters.currency(response.data.balance)
            this.getStatData();
          }
        })
    },
    getStatData() {
      this.statData = [
        {
          type: "line",
          title: "Plano",
          value: this.account.user.package.name,
          icon: "bx-package"
        },
        {
          type: "line",
          icon: "bx-time-five",
          title: "Status",
          value: this.monthly,
          month: true,
        },
        {
          type: "line",
          icon: "bxs-graduation",
          title: "Graduação",
          value: this.graduation.name,
        },
        {
          type: "line",
          icon: "bx-line-chart",
          title: "Previsão de Bônus",
          value: this.total.prediction,
          month: true,
        },
        {
          type: "line",
          icon: "bx-dollar",
          title: "Total de Bônus",
          value: this.total.bonus,
        },
        {
          type: "line",
          icon: "bx-wallet",
          title: "Saldo Disponível",
          value: this.total.balance,
        }
      ]
    },
    getCart() {
      if (!localStorage.cart) {
        api
          .get('store/cart')
          .then(response => {
            if (response.data.status == 'success') {
              this.setStorage(response.data)
            }
          })
          .catch(error => {
            this.cart.errored = error
          })
      } else {
        this.cart = JSON.parse(localStorage.cart)
      }
    },
    setStorage(data) {
      this.cart.items.total = data.items.total
      this.cart.items.list = data.items.list
      this.cart.subtotal = data.subtotal
      this.cart.sale = data.sale
      this.cart.shipping = data.shipping
      this.cart.total = data.total

      localStorage.cart = JSON.stringify(this.cart)
    },
    getBinaryResume() {
      api
        .get('reports/equilibrio')
        .then(response => {
          if (response.data.status == 'success') {
            this.binary.points.left = this.$options.filters.points(response.data.resume.points.left);
            this.binary.points.right = this.$options.filters.points(response.data.resume.points.right);
            this.binary.pairs = this.$options.filters.points(response.data.resume.pairs);
            this.binary.bonus = this.$options.filters.currency(response.data.resume.bonus);
          }
        })
    },
    getBinarySide() {
      api
        .get('network/binary/side')
        .then(response => {
          if (response.data.status == 'success') {
            this.binary.side = response.data.side
          }
        })
    },
    changeBinarySide() {
      this.binary.modal = false;
      this.binary.modal = true;
      this.binary.loading = false;
    },
    saveBinarySide() {
      this.binary.loading = true;

      api
        .post('network/binary/side', {
          side: this.binary.side
        })
        .then(response => {
          if (response.data.status == 'success') {
            this.binary.modal = false;
            this.binary.loading = false;
            this.$toast.success('O lado de derramamento foi alterado com sucesso!')
            this.getBinarySide();
          }
        })
        .finally(() => {
          this.binary.loading = false;
        })
    }
  },
  mounted() {
    this.getMonthly()
    this.getGraduation()
    this.getTotalPrediction()
    this.getTotalBonus()
    this.getTotalBalance()
    this.getStatData()
    this.getCart()
    this.getBinaryResume()
    this.getBinarySide()
  }
};
</script>

<template>
  <Layout>
    <router-link v-if="cart.items.total > 0" to="/store/cart" class="text-dark">
      <div class="card">
        <div class="card-body bg-soft-warning rounded p-0">
          <div class="d-flex align-items-center">
            <img class="rounded-circle header-profile-user m-2 mx-2" :src="cart.items.list[Object.keys(cart.items.list)[0]].image" width="30px" height="30px">
            <div class="py-3">Você deixou alguns produtos em seu carrinho, clique aqui para finalizar o seu pedido!</div>
          </div>
        </div>
      </div>
    </router-link>

    <Banners></Banners>

    <div class="card mb-2">
      <div class="card-body p-1 d-flex justify-content-between align-items-center">
        <div class="mr-2">
          <div class="bg-light rounded p-2">
            <i class="bx bx-link-alt font-size-24 align-middle"></i>
          </div>
        </div>
        <h5 class="flex-fill m-0 align-items-center font-size-16">
          Link de indicação
        </h5>
        <div class="d-flex align-items-center">
          <div class="ml-1">
            <ShareNetwork network="whatsapp" url="" :title="referral.sponsored">
              <div class="bg-light rounded p-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: #0f0d0d;">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M18.403 5.633A8.919 8.919 0 0 0 12.053 3c-4.948 0-8.976 4.027-8.978 8.977 0 1.582.413 3.126 1.198 4.488L3 21.116l4.759-1.249a8.981 8.981 0 0 0 4.29 1.093h.004c4.947 0 8.975-4.027 8.977-8.977a8.926 8.926 0 0 0-2.627-6.35m-6.35 13.812h-.003a7.446 7.446 0 0 1-3.798-1.041l-.272-.162-2.824.741.753-2.753-.177-.282a7.448 7.448 0 0 1-1.141-3.971c.002-4.114 3.349-7.461 7.465-7.461a7.413 7.413 0 0 1 5.275 2.188 7.42 7.42 0 0 1 2.183 5.279c-.002 4.114-3.349 7.462-7.461 7.462m4.093-5.589c-.225-.113-1.327-.655-1.533-.73-.205-.075-.354-.112-.504.112s-.58.729-.711.879-.262.168-.486.056-.947-.349-1.804-1.113c-.667-.595-1.117-1.329-1.248-1.554s-.014-.346.099-.458c.101-.1.224-.262.336-.393.112-.131.149-.224.224-.374s.038-.281-.019-.393c-.056-.113-.505-1.217-.692-1.666-.181-.435-.366-.377-.504-.383a9.65 9.65 0 0 0-.429-.008.826.826 0 0 0-.599.28c-.206.225-.785.767-.785 1.871s.804 2.171.916 2.321c.112.15 1.582 2.415 3.832 3.387.536.231.954.369 1.279.473.537.171 1.026.146 1.413.089.431-.064 1.327-.542 1.514-1.066.187-.524.187-.973.131-1.067-.056-.094-.207-.151-.43-.263">
                  </path>
                </svg>
              </div>
            </ShareNetwork>
          </div>
          <div class="ml-1">
            <button class="btn btn-link p-0 m-0" v-clipboard:copy="referral.sponsored
              " v-on:click="$toast.success('Copiado!');">
              <div class="bg-light rounded p-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: #0f0d0d;">
                  <path
                    d="M20 2H10c-1.103 0-2 .897-2 2v4H4c-1.103 0-2 .897-2 2v10c0 1.103.897 2 2 2h10c1.103 0 2-.897 2-2v-4h4c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zM4 20V10h10l.002 10H4zm16-6h-4v-4c0-1.103-.897-2-2-2h-4V4h10v10z">
                  </path>
                </svg>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-body p-1 d-flex justify-content-between align-items-center">
        <div class="mr-2">
          <div class="bg-light rounded p-2">
            <i class="bx bx-link-alt font-size-24 align-middle"></i>
          </div>
        </div>
        <h5 class="flex-fill m-0 align-items-center font-size-16">
          Link do e-commerce
        </h5>
        <div class="d-flex align-items-center">
          <div class="ml-1">
            <ShareNetwork network="whatsapp" url="" :title="referral.sponsored">
              <div class="bg-light rounded p-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: #0f0d0d;">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M18.403 5.633A8.919 8.919 0 0 0 12.053 3c-4.948 0-8.976 4.027-8.978 8.977 0 1.582.413 3.126 1.198 4.488L3 21.116l4.759-1.249a8.981 8.981 0 0 0 4.29 1.093h.004c4.947 0 8.975-4.027 8.977-8.977a8.926 8.926 0 0 0-2.627-6.35m-6.35 13.812h-.003a7.446 7.446 0 0 1-3.798-1.041l-.272-.162-2.824.741.753-2.753-.177-.282a7.448 7.448 0 0 1-1.141-3.971c.002-4.114 3.349-7.461 7.465-7.461a7.413 7.413 0 0 1 5.275 2.188 7.42 7.42 0 0 1 2.183 5.279c-.002 4.114-3.349 7.462-7.461 7.462m4.093-5.589c-.225-.113-1.327-.655-1.533-.73-.205-.075-.354-.112-.504.112s-.58.729-.711.879-.262.168-.486.056-.947-.349-1.804-1.113c-.667-.595-1.117-1.329-1.248-1.554s-.014-.346.099-.458c.101-.1.224-.262.336-.393.112-.131.149-.224.224-.374s.038-.281-.019-.393c-.056-.113-.505-1.217-.692-1.666-.181-.435-.366-.377-.504-.383a9.65 9.65 0 0 0-.429-.008.826.826 0 0 0-.599.28c-.206.225-.785.767-.785 1.871s.804 2.171.916 2.321c.112.15 1.582 2.415 3.832 3.387.536.231.954.369 1.279.473.537.171 1.026.146 1.413.089.431-.064 1.327-.542 1.514-1.066.187-.524.187-.973.131-1.067-.056-.094-.207-.151-.43-.263">
                  </path>
                </svg>
              </div>
            </ShareNetwork>
          </div>
          <div class="ml-1">
            <button class="btn btn-link p-0 m-0" v-clipboard:copy="referral.ecommerce
              " v-on:click="$toast.success('Copiado!');">
              <div class="bg-light rounded p-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: #0f0d0d;">
                  <path
                    d="M20 2H10c-1.103 0-2 .897-2 2v4H4c-1.103 0-2 .897-2 2v10c0 1.103.897 2 2 2h10c1.103 0 2-.897 2-2v-4h4c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zM4 20V10h10l.002 10H4zm16-6h-4v-4c0-1.103-.897-2-2-2h-4V4h10v10z">
                  </path>
                </svg>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div v-for="stat of statData" :key="stat.icon" class="col-md-6 col-lg-4">
        <Stat :type="stat.type" :icon="stat.icon" :title="stat.title" :value="stat.value" :month="stat.month" />
      </div>
    </div>

    <fieldset class="binary pb-0">
      <legend>
        Binário <sup class="font-weight-light">{{ month_year }}</sup>
      </legend>
      <div class="binary-config">
        <button v-on:click="changeBinarySide()">
          <div class=" d-flex align-items-center">
            <div class="side">
              <template v-if="binary.side == 'l'">Esquerdo</template>
              <template v-if="binary.side == 'r'">Direito</template>
              <template v-else>...</template>
            </div>
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
                <path d="M12 16c2.206 0 4-1.794 4-4s-1.794-4-4-4-4 1.794-4 4 1.794 4 4 4zm0-6c1.084 0 2 .916 2 2s-.916 2-2 2-2-.916-2-2 .916-2 2-2z"></path>
                <path
                  d="m2.845 16.136 1 1.73c.531.917 1.809 1.261 2.73.73l.529-.306A8.1 8.1 0 0 0 9 19.402V20c0 1.103.897 2 2 2h2c1.103 0 2-.897 2-2v-.598a8.132 8.132 0 0 0 1.896-1.111l.529.306c.923.53 2.198.188 2.731-.731l.999-1.729a2.001 2.001 0 0 0-.731-2.732l-.505-.292a7.718 7.718 0 0 0 0-2.224l.505-.292a2.002 2.002 0 0 0 .731-2.732l-.999-1.729c-.531-.92-1.808-1.265-2.731-.732l-.529.306A8.1 8.1 0 0 0 15 4.598V4c0-1.103-.897-2-2-2h-2c-1.103 0-2 .897-2 2v.598a8.132 8.132 0 0 0-1.896 1.111l-.529-.306c-.924-.531-2.2-.187-2.731.732l-.999 1.729a2.001 2.001 0 0 0 .731 2.732l.505.292a7.683 7.683 0 0 0 0 2.223l-.505.292a2.003 2.003 0 0 0-.731 2.733zm3.326-2.758A5.703 5.703 0 0 1 6 12c0-.462.058-.926.17-1.378a.999.999 0 0 0-.47-1.108l-1.123-.65.998-1.729 1.145.662a.997.997 0 0 0 1.188-.142 6.071 6.071 0 0 1 2.384-1.399A1 1 0 0 0 11 5.3V4h2v1.3a1 1 0 0 0 .708.956 6.083 6.083 0 0 1 2.384 1.399.999.999 0 0 0 1.188.142l1.144-.661 1 1.729-1.124.649a1 1 0 0 0-.47 1.108c.112.452.17.916.17 1.378 0 .461-.058.925-.171 1.378a1 1 0 0 0 .471 1.108l1.123.649-.998 1.729-1.145-.661a.996.996 0 0 0-1.188.142 6.071 6.071 0 0 1-2.384 1.399A1 1 0 0 0 13 18.7l.002 1.3H11v-1.3a1 1 0 0 0-.708-.956 6.083 6.083 0 0 1-2.384-1.399.992.992 0 0 0-1.188-.141l-1.144.662-1-1.729 1.124-.651a1 1 0 0 0 .471-1.108z">
                </path>
              </svg>
            </div>
          </div>
        </button>
      </div>
      <div class="row">
        <div class="col-6 col-md-6 col-lg-3 mb-3">
          <div class="d-flex align-items-center">
            <div>
              <div class="bg-light rounded py-1 px-2">
                <i class="bx bx-subdirectory-left font-size-24 my-1 align-middle"></i>
              </div>
            </div>
            <div class="flex-fill ml-2">
              <h5 class="mb-0 notranslate font-weight-bold">{{ binary.points.left }}</h5>
              <p class="font-weight-medium mb-0 text-uppercase font-weight-light font-size-12">L. Esquerdo</p>
            </div>
          </div>
        </div>
        <div class="col-6 col-md-6 col-lg-3 mb-3">
          <div class="d-flex align-items-center">
            <div>
              <div class="bg-light rounded py-1 px-2">
                <i class="bx bx-subdirectory-right font-size-24 my-1 align-middle"></i>
              </div>
            </div>
            <div class="flex-fill ml-2">
              <h5 class="mb-0 notranslate font-weight-bold">{{ binary.points.right }}</h5>
              <p class="font-weight-medium mb-0 text-uppercase font-weight-light font-size-12">L. Direito</p>
            </div>
          </div>
        </div>
        <div class="col-6 col-md-6 col-lg-3 mb-3">
          <div class="d-flex align-items-center">
            <div>
              <div class="bg-light rounded py-1 px-2">
                <i class="bx bx-check-double font-size-24 my-1 align-middle"></i>
              </div>
            </div>
            <div class="flex-fill ml-2">
              <h5 class="mb-0 notranslate font-weight-bold">{{ binary.pairs }}</h5>
              <p class="font-weight-medium mb-0 text-uppercase font-weight-light font-size-12">Pares</p>
            </div>
          </div>
        </div>
        <div class="col-6 col-md-6 col-lg-3 mb-3">
          <div class="d-flex align-items-center">
            <div>
              <div class="bg-light rounded py-1 px-2">
                <i class="bx bx-dollar font-size-24 my-1 align-middle"></i>
              </div>
            </div>
            <div class="flex-fill ml-2">
              <h5 class="mb-0 notranslate font-weight-bold">{{ binary.bonus }}</h5>
              <p class="font-weight-medium mb-0 text-uppercase font-weight-light font-size-12">Bônus</p>
            </div>
          </div>
        </div>
      </div>
    </fieldset>

    <b-modal v-model="binary.modal" title="Lado de Derramento" modal-class="modal-right" hide-footer>
      <b-form @submit.prevent="saveBinarySide()">
        <b-form-group label="Escolha um lado" label-for="binary.side">
          <select v-model="binary.side" class="custom-select mb-2">
            <option value="l">Lado Esquerdo</option>
            <option value="r">Lado Direto</option>
          </select>
        </b-form-group>
        <b-button class="mb-0" :disabled="this.binary.loading" type="submit" variant="default" size="lg">
          Salvar
          <b-spinner v-if="binary.loading" small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
        </b-button>
      </b-form>
    </b-modal>

    <div class="row">
      <div class="col-md-12 col-lg-6">
        <fieldset class="card-h-fix">
          <legend>
            Graduação <sup class="font-weight-light">{{ month_year }}</sup>
          </legend>
          <div class="d-flex justify-content-center card-h-fix">
            <ChartGraduation />
          </div>
        </fieldset>
      </div>
      <div class="col-md-12 col-lg-6">
        <fieldset class="card-h-fix">
          <legend>
            Bonificações <sup class="font-weight-light">{{ month_year }}</sup>
          </legend>
          <div class="d-flex justify-content-center card-h-fix">
            <ChartBonus />
          </div>
        </fieldset>
      </div>
      <div class="col-md-12 col-lg-6">
        <fieldset class="card-h-fix">
          <legend>
            Pontuações <sup class="font-weight-light">{{ month_year }}</sup>
          </legend>
          <div class="d-flex justify-content-center card-h-fix">
            <ChartPoints />
          </div>
        </fieldset>
      </div>
      <div class="col-md-12 col-lg-6">
        <fieldset class="card-h-fix">
          <legend>
            Pedidos <sup class="font-weight-light">{{ month_year }}</sup>
          </legend>
          <div class="d-flex justify-content-center card-h-fix">
            <ChartOrders />
          </div>
        </fieldset>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.binary {
  position: relative;
}
.binary-config {
  position: absolute;
  top: -27px;
  right: 15px;
  border: solid 1px #D1D1E3;
  border-radius: 6px;
}
.binary-config button {
  background: #fff;
  border: 0;
  border-radius: 50%;
}
.binary-config button .side {
  padding: 5px;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}
</style>