<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";
import Stat from "@/components/widgets/stat";
import VueMonthlyPicker from 'vue-monthly-picker'
import moment from 'moment';

export default {
  locales: {
    pt: {
      'Graduation': 'Graduação',
      'Points': 'Pontos',
      'Premiation': 'Premiação',
      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.',
      'No records found.': 'Nenhum registro encontrado.',
    },
    es: {
      'Graduation': 'Patrocinio',
      'Points': 'Puntos',
      'Premiation': 'Premiación',
      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.',
      'No records found.': 'No se encontraron registros.',
    }
  },
  components: {
    Layout,
    Stat,
    VueMonthlyPicker
  },
  data() {
    return {
      calendar: "",
      monthly: {
        placeHolder: moment().format('MMM/YYYY'),
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      },
      date: moment().format('YYYY-MM'),

      table: {
        body: null,
        loading: true,
      },

      statData: null,

      graduation: {
        previous: '-',
        points: '0',
        next: '-',
        percent: 0
      },
    };
  },
  methods: {
    getGraduation() {
      this.table.body = null

      this.table.loading = true

      api
        .get('reports/graduation?date=' + this.date)
        .then(response => {
          if (response.data.status == 'success') {
            this.statData = [
              {
                type: "line",
                icon: "bxs-graduation",
                title: "Graduação",
                value: response.data.graduation.name,
              },
              {
                type: "line",
                icon: "bx-user",
                title: "Pontos Pessoais",
                value: this.$options.filters.points(response.data.graduation.points.personal)
              },
              {
                type: "line",
                icon: "bx-bar-chart-square",
                title: "Pontos Aproveitados",
                value: this.$options.filters.points(response.data.graduation.points.used)
              },
              {
                type: "line",
                icon: "bx-chart",
                title: "Pontos Totais",
                value: this.$options.filters.points(response.data.graduation.points.total)
              }
            ]

            if (response.data.graduation.next) {
              this.graduation.previous = response.data.graduation.name
              this.graduation.points = response.data.graduation.points.used
              this.graduation.next = response.data.graduation.next.name
              this.graduation.percent = ((response.data.graduation.points.used - response.data.graduation.previous.points) * 100) / (response.data.graduation.next.points - response.data.graduation.previous.points)
            } else {
              this.graduation.previous = response.data.graduation.name
              this.graduation.points = response.data.graduation.points.used
              this.graduation.next = '-'
              this.graduation.percent = 100
            }

            this.table.body = response.data.list
          } else {
            this.statData = [
              {
                type: "line",
                icon: "bxs-graduation",
                title: "Graduação",
                value: "-",
              },
              {
                type: "line",
                icon: "bx-bar-chart-square",
                title: "Pontos Aproveitados",
                value: "0",
              }
            ]
          }
        })
        .catch(() => {
          this.table.body = []
        })
    }
  },
  mounted() {
    this.getGraduation()
  },
  watch: {
    calendar: function (v) {
      this.date = moment(v._d).format('YYYY-MM')
      this.getGraduation()
    }
  }
};
</script>

<template>
  <Layout>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <router-link tag="a" to="/">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
            <path d="M5 22h14a2 2 0 0 0 2-2v-9a1 1 0 0 0-.29-.71l-8-8a1 1 0 0 0-1.41 0l-8 8A1 1 0 0 0 3 11v9a2 2 0 0 0 2 2zm5-2v-5h4v5zm-5-8.59 7-7 7 7V20h-3v-5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v5H5z">
            </path>
          </svg>
        </router-link>
      </li>
      <li class="breadcrumb-item">Relatórios</li>
    </ol>

    <div class="page-title-box d-md-flex justify-content-between">
      <div>
        <h3>{{ t('Graduation') }}</h3>
        <p></p>
      </div>
      <vue-monthly-picker v-model="calendar" dateFormat="MMM/YYYY" :placeHolder="monthly.placeHolder" :monthLabels="monthly.labels" :clearOption="false" alignment="center"></vue-monthly-picker>
    </div>

    <div class="row">
      <div v-for="stat of statData" :key="stat.icon" class="col-md-6 col-xl-3">
        <Stat :type="stat.type" :icon="stat.icon" :title="stat.title" :value="stat.value" :image="stat.image" />
      </div>
    </div>

    <div v-if="table.body" class="card">
      <div class="card-body">
        <b-progress style="border-radius: 3px;" :value="Number(graduation.percent)" variant="default" height="15px"></b-progress>
        <div class="d-flex justify-content-between">
          <div>
            {{ graduation.previous }}
          </div>
          <div class="text-center">
            {{ graduation.percent.toFixed(2) }}%
          </div>
          <div>
            {{ graduation.next }}
          </div>
        </div>
      </div>
    </div>

    <div v-if="!table.body" class="card">
      <div class="card-body min-vh-25 d-flex justify-content-center align-items-center">
        <b-spinner variant="default" role="status"></b-spinner>
      </div>
    </div>

    <div v-else-if="table.body && table.body.length == 0" class="card">
      <div class="card-body min-vh-25 d-flex justify-content-center align-items-center">
        <div class="text-center">
          <i class="far fa-sad-cry font-size-24"></i><br>
          nenhum registro encontrado
        </div>
      </div>
    </div>

    <div v-else class="row">
      <div v-for="(td, index) in table.body" :key="index" class="col-xl-3 col-md-6">
        <div class="card" :class="td.status">
          <div class="card-body d-flex align-items-center py-2 px-3">
            <div v-if="td.status == 'graduated'" class="graduation-bookmark">
              <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
                <path d="M19 10.132v-6c0-1.103-.897-2-2-2H7c-1.103 0-2 .897-2 2V22l7-4.666L19 22V10.132z"></path>
              </svg>
            </div>
            <div class="graduation-icon">
              <img v-if="td.image" :src="require('@/assets/images/graduations/' + td.image)" />
              <img v-else src="@/assets/images/logo-icon.png" class="" />
            </div>
            <div class="ml-3">
              <h6 class="mb-0 font-weight-normal">{{ td.name }}</h6>
              <h4 class="mb-0">{{ td.points | points }} <small class="font-size-14">pontos</small></h4>
              <h6 class="mb-0">{{ td.vml | points }} <small class="font-size-14">vml</small></h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.graduation-bookmark {
  position: absolute;
  top: -7px;
  right: 0;
}
.graduation-icon img {
  background: #CED4DB;
  border-radius: 100%;
  width: 80px;
  height: 80px;
}
.ungraduated {
  opacity: 0.5;
}
</style>